<template>
  <div class="retrieve-page">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span v-if="route.query.type === 'recovery'">平台快速回收</span>
      <span v-if="route.query.type === 'medium'">担保中介</span>
    </div>
    <div class="box">
      <div class="title">
        <div class="left">
          <img src="@/assets/images/service-icon3.png" alt="" />
          <p>支付方式</p>
        </div>
        <div class="right">
          <p v-if="route.query.type === 'recovery'" @click="gopage">回收规则</p>
          <p v-if="route.query.type === 'medium'" @click="gopage">担保规则</p>
          <img src="@/assets/images/icon-right.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <ServiceList :list="list" v-if="list.length > 0" :type="route.query.type"></ServiceList> -->
    <div class="list" v-if="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index" @click="serviceClick(item)">
        <p class="title">使用微信扫描二维码添加</p>
        <div class="img">
          <img :src="item.image" alt="" />
        </div>
        <p class="">{{ item.name }}</p>
        <div class="btn">快速咨询</div>
      </div>
    </div>
    <el-empty description="暂无数据" v-else />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import ServiceList from '@/components/ServiceList/index.vue'
import { service } from '@/api/user'
import { useRoute, useRouter } from 'vue-router'
import { getChatService } from '@/api/service'
const route = useRoute()
const router = useRouter()
const list = ref([])
const getList = () => {
  let type = null
  if (route.query.type === 'recovery') {
    type = 0
  } else if (route.query.type === 'medium') {
    type = 1
  }
  service({ type: type }).then((res) => {
    if (res.code === 200) {
      list.value = res.data.list
    }
  })
}
getList()
const serviceClick = () => {
  let type = null
  if (route.query.type === 'recovery') {
    type = 2
  } else if (route.query.type === 'medium') {
    type = 3
  }
  getChatService({ type: type }).then((res) => {
    if (res.code == 0) {
      let to = router.resolve({ path: '/service', query: { account: res.data.id } })
      window.open(to.href, '_blank')
    }
  })
}
const gopage = () => {
  if (route.query.type === 'recovery') {
    router.push({ path: '/article', query: { id: 17 } })
  } else if (route.query.type === 'medium') {
    router.push({ path: '/article', query: { id: 16 } })
  }
}
</script>
<style lang="less" scoped>
.retrieve-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .breadcrumb {
    margin: 14px 0;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    img {
      margin: 0 3px;
    }
    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      cursor: auto;
    }
  }
  .box {
    .title {
      width: 1200px;
      height: 45px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      border-radius: 12px 12px 0px 0px;
      display: flex;
      justify-content: space-between;
      padding: 0 18px;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-left: 5px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
.list {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  background: #ffffff;
  padding: 0 21px;
  padding-bottom: 21px;
  border-radius: 0 0 12px 12px;
  .item {
    text-align: center;
    background: #fafafa;
    border-radius: 12px;
    padding: 16px 29px;
    margin-top: 21px;
    .title {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .img {
      width: 134px;
      height: 134px;
      margin: 0 auto;
      margin-top: 16px;
      img {
        width: 134px;
        height: 134px;
      }
    }
    p:nth-child(3) {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-top: 19px;
    }
    .btn {
      width: 97px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      border-radius: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-top: 8px;
      cursor: pointer;
    }
  }
}
</style>
